<template>
  <CRow>
    <CModal
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
        :hide-footer="true"
    >

      <img alt="Document" :src="viewImage"/>

      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.post_media.title }}</strong>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <div v-if="totalMedia(mediaLists) > 0">
            <div id="people">
              <div class="row row-sm">
                <div
                    v-for="(media, index) in mediaLists"
                    :key="media.id"
                    class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-2"
                >
                  <div class="card card-file media">
                    <div class="card-file-thumb">
                      <i class="fa fa-file-word-o"></i>
                    </div>
                    <div class="card-body text-center">
                      <div v-if="media.mediaName === 'Image'">
                        <img
                            :src="media.url"
                            alt="Media"
                            height="98"
                            @click="open(media.url, media.type)"
                            style="cursor: pointer"
                            class="mx-auto d-block"
                        />
                        <a :href="media.url" alt="Download" title="Download">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              role="img"
                              class="c-icon c-icon-custom-size"
                              style="color: #3c4b64; margin-top: 6px"
                              height="17"
                          >
                            <path
                                fill="var(--ci-primary-color, currentColor)"
                                d="M255.682,494.636,16,254.3V216.024l143.937-.007V16h192V216.007L495.952,216l-.035,38.688ZM54.931,248.022l200.8,201.342L457.328,248l-137.391.008V48h-128V248.015Z"
                                class="ci-primary"
                            ></path>
                          </svg>
                        </a>
                      </div>
                      <div v-else-if="media.mediaName !== 'Image'">
                        <img
                            alt="Default Image"
                            :src="media.defaultImage"
                            class="mx-auto d-block"
                        />
                        <a :href="media.url" alt="Download" title="Download">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              role="img"
                              class="c-icon c-icon-custom-size"
                              style="color: #3c4b64"
                              height="17"
                          >
                            <path
                                fill="var(--ci-primary-color, currentColor)"
                                d="M255.682,494.636,16,254.3V216.024l143.937-.007V16h192V216.007L495.952,216l-.035,38.688ZM54.931,248.022l200.8,201.342L457.328,248l-137.391.008V48h-128V248.015Z"
                                class="ci-primary"
                            ></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            {{ $lang.post_media.label.no_content }}
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {postMedia} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "PostMedia",
  mixins: [Mixin],
  data() {
    return {
      defaultImage: "",
      err_msg: "",
      count: "",
      largeModal: false,
      ViewResponse: "",
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      reasonOptions: [],
      mediaLists: [],
      activePage: 1,
      listUrl: "",
      module: postMedia,
      data: [],
    };
  },
  created() {
    let self = this;
    self.listUrl = "/posts/media/list/";
    store.commit("showLoader", false); // Loader Off
  },
  mounted() {
    let self = this;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    self.defaultImage = store.state.defaultUploadImage.doc;
    self.listUrl = this.listUrl + self.editId;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    axios.get(self.listUrl, self.model).then(function (response) {
      if (response) {
        self.mediaLists = response.data;
      } else {
        self.data = [];
      }
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    open(url, type) {
      let self = this;
      self.viewImage = url;
      self.Type = type;
      //  fancyBox(e.target,this.images);
      self.largeModal = true;
    },
    totalMedia(items) {
      return items.length;
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
<style>
.media img {
  width: 100%;
}

.modal-body > img {
  width: 100%
}

</style>
